export const menuList = [
  {
    redirect: "/about-us",
    name: "About Us",
  },
  {
    redirect: "/production",
    name: "Production",
  },
  {
    redirect: "/applications",
    name: "Applications",
  },
  {
    redirect: "/projects",
    name: "Projects",
  },
  {
    redirect: "/contact-us",
    name: "Contact Us",
  },
]

export const serviceList = [
  {
    id: 0,
    title: "Surveying",
  },
  {
    id: 1,
    title: "Civil Work",
  },
  {
    id: 2,
    title: "Production",
  },
  {
    id: 3,
    title: "Erection",
  },
]
